<template>
  <div class="header-bg">
    <div class="carousel" ref="lb">
      <Nav class="nav"></Nav>
      <!-- 轮播图组件 -->
      <Carousel :carouselData="carouselData"></Carousel>
    </div>
  </div>
</template>

<script>
import Nav from "./HeaderNav.vue";
import HeaderStyle from "@/components/commom/HeaderStyle.vue";
import Carousel from "@/components/layer/Carousel.vue";
import { clearTimeout } from "timers";
import { getCookie } from "@/utils";

export default {
  name: "AppHeader",
  components: { Nav, HeaderStyle,Carousel },
  data() {
    return {
      carouselData:[
        {
          url:require("../../assets/home/bg.png"),

        },
      ],
      carouselInfo: [
        {
          img: require("@/assets/home/01.png"),
          title: "领先的社会治理服务运营商",
          intro_one: "",
          // 解决社会治理人才、能力需求，提供具有商业价值的社会治理类型化产品
          intro_two: "扩展业务场景，提升政法业务触达能力",
          target_url: "/1",
        },
        // {
        //   img: require('@/assets/openSkill/tt3x.png'),
        //   title: '更开放的联盟服务生态',
        //   intro_one: '底层技术开放、共建节点开放、应用接入开放',
        //   target_url: '/2'
        // },
        // {
        //   img: require('@/assets/home/1503.png'),
        //   title: '合作共建',
        //   intro_one: '中国网安、腾讯公司、北明软件作为联盟发起者，期待您的加入',
        //   target_url: '/3'
        // }
      ],
      // 当前索引
      currentIndex: 3,
      // 定时器
      timer: "",
      // 小圆点开关
      begin: "",
      noLogin: null,
    };
  },
  beforeMount() {
    this.noLogin = getCookie("userData");
  },
  methods: {
    goin() {
      const url = window.location.href.replace(/www/, "open");
      window.location.href = url;
      // console.log(url.replace(/www/, "open"))
    },
    go() {
      this.timer = setInterval(() => {
        this.autoPlay();
      }, 5000);
    },
    stop() {
      clearInterval(this.timer);
      this.timer = null;
    },
    change(index) {
      this.currentIndex = index;
    },
    autoPlay() {
      this.currentIndex++;
      if (this.currentIndex > this.carouselInfo.length - 1) {
        this.currentIndex = 0;
      }
    },
    carouseTransition() {
      this.begin = false;
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (this.begin) {
        return;
      }
      this.begin = true;
      this.autoPlay();
      this.timer = setInterval(() => {
        this.autoPlay();
      }, 5000);
    });
  },
};
</script>

<style scoped lang="scss">
.hj-carousel {
  position: relative;
  width: 100%;
  padding-bottom: 37.5%;
  .goOpen {
    position: absolute;
    left: 60%;
    top: 20%;
    padding:0.10rem;
    text-align: center;
    z-index: 3999;
    // width: 260px;
    // height: 200px;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 0.08rem ;

    p {
      margin: 0.10rem 0;
    }
    .goBtn {
      background-color: #3b63df;
      padding: 0.05rem 0.15rem;
      color: #fff;
      margin-top: 0.15rem;
      cursor: pointer;
      border-radius: 0.02rem;
    }
  }
}
.header-bg {
  width: 100%;
  position: relative;
  overflow: hidden;
  .pa-con {
    display: flex;
    justify-content: center;
    width: 100%;
    position: absolute;
    align-items: flex-end;
    z-index: 1001;
    bottom: 0.20rem;
  }
  .items {
    .active1 {
      background-color: #fff !important;
    }
  }
  .items .item {
    width: 0.60rem;
    height: 0.06rem;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 0.06rem;
    display: inline-block;
    margin: 0 0.10rem;
    cursor: pointer;
  }
  .items .item:hover {
    background-color: #fff;
  }
}
.list-item-word {
}
.list-item2 {
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  overflow: hidden;
  .activeWord {
    z-index: 500;
    opacity: 1;
  }
  .dispWord {
    opacity: 0;
    z-index: -500;
  }
  .intro-box2 {
    height: 100%;
    .service-intro2 {
      width: 42%;
      position: absolute;
      top: 46%;
      left: 30%;
      color: #fff;
      h2 {
        height: 0.53rem;
        line-height: 0.53rem;
        font-size: 0.52rem;
        font-weight: 600;
        text-align: center;
      }
      div {
        // display: flex;
        margin-top: 0.20rem;
        justify-content: center;
        text-align: center;
        p {
          height: 0.30rem;
          line-height: 0.30rem;
          font-size: 0.18rem;
        }
      }
    }
  }
  .list-item-img2 {
    width: 100%;
    display: block;
    transform: scale(1);
    opacity: 0;
    transition: all 3s linear;
    float: left;
    z-index: 1;
  }
  .list-item-img3 {
    width: 100%;
    height:100%;
    display: block;
    transform: scale(1);
    opacity: 0;
    transition: all 3s linear;
    float: left;
    z-index: 1;
    opacity: 0;
  }
}
.active {
  z-index: 2;
  .list-item-img2 {
    transform: scale(1.1, 1.1);
    opacity: 1;
  }
}
.list2-enter,
.list2-leave-to {
  opacity: 0;
}
.list2-enter-to,
.list2-leave {
  opacity: 1;
}
.list2-leave-active,
.list2-enter-active {
  transition: all 2s linear;
}
</style>
