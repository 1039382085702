<!--
 * @Author: wq
 * @Description: 图片预览的弹框 (支持 放大、缩小、切换)
 * @Date: 2020-04-17 10: 05:58
 -->

<template>
	<el-dialog
		:visible.sync="config.show"
		class="img-pre-dialog"
		@closed="closed"
		@opened="opened"
		@open="open"
	>
		<div slot="title" class="btns">
			<!-- <el-button type="text" size="small" @click="sizeAdd()">
				放大
			</el-button>
			<el-button type="text" size="small" @click="sizeSub()">
				缩小
			</el-button>
			<el-button type="text" size="small" @click="rotateSub()">
				旋转
			</el-button>
			<el-button type="text" size="small" @click="sizeOrign()">
				还原
			</el-button> -->
		</div>

		<div ref="preContainer" class="carousel-wrap">
			<!-- <transition-group tag="ul" :name="animateName"> -->
			<!-- <li v-for="(img,idx) in config.data" :key="idx" v-show="idx===curIndex"> -->
			<img :src="config.img" draggable="true" class="image" />
			<!-- </li> -->
			<!-- </transition-group> -->
		</div>
	</el-dialog>
</template>

<script>
export default {
	props: {
		config: {
			type: Object,
			default: () => {
				return {
					show: false,
					img: () => {
						return "";
					},
				};
			},
		},
	},
	data() {
		return {
			rotatedeg: 0, // 默认旋转度数
			curIndex: 0,
			animateName: "carousel-left",
			ratio: 0.25, //缩放比率
			// 放大相关参数
			scale: 1,
			minScale: 0.5,
			x: 0,
			y: 0,
			left: 0,
			top: 0,
			isMove: false,
			imgWrapWidth: 0,
			imgWrapHeight: 0,
		};
	},
	methods: {
		open() {
			this.animateName = "";
			this.curIndex = this.config.index || 0;
		},
		opened() {
			this._initImageData();
			const oImg = this.$refs.preContainer.querySelectorAll(".image");
			oImg.forEach((element) => {
				this._bindMove(element);
			});
		},
		closed() {
			this.curIndex = 0;
			this.animateName = "";
			this.scale = 1;
			this.x = 0;
			this.y = 0;
			this.left = 0;
			this.top = 0;
			this.isMove = false;
			this.config.data = [];
		},
		change(index) {
			this.animateName = index > 0 ? "carousel-right" : "carousel-left";

			let nextIndex = this.curIndex + index;
			const length = this.config.data.length;
			nextIndex = Math.max(0, nextIndex);
			this.curIndex = Math.min(length - 1, nextIndex);
			this.sizeOrign();
		},
		sizeAdd() {
			this.scale += this.ratio;
			const el =
				this.$refs.preContainer.querySelectorAll(".image")[
					this.curIndex
				];
			this._changeTransform(el);
		},
		rotateSub() {
			//默认顺时针旋转90度
			this.rotatedeg -= 90;
			const el =
				this.$refs.preContainer.querySelectorAll(".image")[
					this.curIndex
				];

			this._changeTransform(el);
		},
		sizeSub() {
			this.scale -= this.ratio;
			this.scale = Math.max(this.scale, this.minScale);
			const el =
				this.$refs.preContainer.querySelectorAll(".image")[
					this.curIndex
				];
			// 根据拖动的距离 计算相对比例的缩小距离(动画结束后移动)
			// setTimeout(() => {
			//     let disTop = parseInt(el.style.top) * (this.scale / (this.scale + this.ratio));
			//     let disLeft = parseInt(el.style.left) * (this.scale / (this.scale + this.ratio));
			//     el.style.top = disTop + 'px';
			//     el.style.left = disLeft + 'px';
			// }, 300);

			el.style.top = 0;
			el.style.left = 0;

			this._changeTransform(el);
		},
		sizeOrign() {
			this.scale = 1;
			this.rotatedeg = 0;
			const el = this.$refs.preContainer.querySelectorAll(".image");
			el.forEach((element) => {
				element.style.top = 0;
				element.style.left = 0;
				this._changeTransform(element);
			});
		},

		_bindMove(el) {
			var _this = this;
			el.onmousedown = function (event) {
				event = event || window.event;

				_this.isMove = true;
				var imgWidth = this.offsetWidth * _this.scale;
				var imgHeight = this.offsetHeight * _this.scale;
				_this.x = event.clientX;
				_this.y = event.clientY;
				_this.left = parseInt(this.style.left || 0);
				_this.top = parseInt(this.style.top || 0);
				_this.clientL = (imgWidth - _this.imgWrapWidth) / 2;
				_this.clientT = (imgHeight - _this.imgWrapHeight) / 2;
				event.preventDefault();
			};

			el.onmousemove = function (event) {
				event = event || window.event;
				event.preventDefault && event.preventDefault();
				// 正在移动或缩放不超过1倍的不改变位置
				if (!_this.isMove || _this.scale <= 1) return;

				var imgWidth = this.offsetWidth * _this.scale;
				var imgHeight = this.offsetHeight * _this.scale;
				var x1 = event.clientX - _this.x + _this.left;
				var y1 = event.clientY - _this.y + _this.top;

				var getMoveDis = function (num, clientDis) {
					if (num > clientDis || num < -clientDis) {
						num > 0 ? (num = clientDis) : (num = -clientDis);
					}
					return num;
				};
				if (imgHeight > _this.imgWrapHeight) {
					this.style.top = getMoveDis(y1, _this.clientT) + "px";
				}
				if (imgWidth > _this.imgWrapWidth) {
					let dis = getMoveDis(x1, _this.clientL);
					const maxLeft = (imgWidth - _this.imgWrapWidth) / 2;
					if (dis > maxLeft) dis = maxLeft;
					if (dis < -maxLeft) dis = -maxLeft;
					this.style.left = dis + "px";
				}
			};
			document.onmouseup = function (e) {
				_this.isMove = false;
				_this.x = 0;
				_this.y = 0;
				e.preventDefault();
			};
		},

		// 私有方法
		_initImageData() {
			const imageBox = this.$refs.preContainer;
			this.imgWrapWidth = imageBox.clientWidth;
			this.imgWrapHeight = imageBox.clientHeight;
		},
		// _imgComplete() {
		//     let imagesEl = this.$refs.preContainer.querySelectorAll('.image');
		//     let _self = this;
		//     imagesEl.forEach((image,index) => {
		//         image.onload = image.onreadystatechange = function() {
		//             if (!this.readyState || this.readyState == 'loaded' || this.readyState == 'complete') {
		//                 var x = this.naturalWidth || this.width
		//                 var y = this.naturalHeight || this.height
		//                 var imgWidth = 0
		//                 var imgHeight = 0
		//                 var ratio = 1
		//                 if (!x || !y) return
		//                 ratio = x / y

		//                 // 图片高度大于宽度 并且大于视图高度
		//                 if (ratio < 1 && y > _self.imgWrapHeight) {
		//                 // 计算图片宽高比
		//                     imgWidth = parseInt(x * ratio)
		//                     imgHeight = _self.imgWrapHeight
		//                 } else if (ratio > 1 && x > _self.imgWrapWidth) {
		//                     imgWidth = _self.imgWrapWidth;
		//                     imgHeight = parseInt(y * ratio);
		//                 } else {
		//                     imgWidth = x;
		//                     imgHeight = y;
		//                 }
		//                 // image.style.width = imgWidth + 'px'
		//                 // image.style.height = imgHeight + 'px'
		//             }
		//         };
		//     });
		// },
		_changeTransform(el) {
			var imgWidth = el.offsetWidth * this.scale;
			var imgHeight = el.offsetHeight * this.scale;
			if (
				imgWidth <= this.imgWrapWidth &&
				imgHeight <= this.imgWrapHeight
			) {
				el.style.cursor = "";
			} else {
				el.style.cursor = "move";
			}
			this._setScale(el, this.scale, this.scale);
		},
		_setScale(el, x, y) {
			// el.style.transform = "scale3d(" + x + ", " + y + ", 1)";
			// el.style.WebkitTransform = "scale3d(" + x + ", " + y + ", 1)";
			el.style.transform = `scale3d(${x},${y},1) rotate(${this.rotatedeg}deg)`;
			el.style.WebkitTransform = `scale3d(${x},${y},1) rotate(${this.rotatedeg}deg)`;
		},
	},
};
</script>

<style lang="scss" scoped>
.img-pre-dialog {
	/deep/ .el-dialog {
		// width: 90%;
		// height: 100%;
		margin: 0 auto;
	}
	/deep/ .el-dialog__header {
		padding: 0;
		height: 40px;
		line-height: 40px;
		text-align: center;
	}

	/deep/ .el-dialog__header {
		padding: 0 16px 0 16px;
		height: 40px;
		line-height: 40px;
		background: #f2f2f2;
		font-size: 16px;
		font-weight: 500;

		.el-dialog__headerbtn {
			top: 10px;
			font-size: 24px;
		}
	}
	/deep/ .el-button + .el-button {
		margin-left: 16px * 2;
	}

	/deep/ .el-dialog__body {
		padding: 0;
		height: calc(100% - 40px);
	}

	.carousel-wrap {
		height: 100%;
		background-color: #fff;
		overflow: hidden;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.image-box {
		display: inline-block;
	}
	.image {
		position: relative;
		transition: transform 0.3s ease-out;
		max-width: 100%;
		max-height: 100%;
		margin: 0px 120px;
		// text-align: center;
	}
	.carousel-wrap ul {
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
		width: 100%;
		height: 100%;
	}
	.carousel-wrap li {
		position: absolute;
		width: 100%;
		height: 100%;
		text-align: center;
		display: inline-flex;
		align-items: center;
		justify-content: center;
	}

	// 左侧进入
	.carousel-left-enter-active {
		transform: translateX(0);
		transition: all 0.5s ease-in-out;
	}
	.carousel-left-leave-active {
		transform: translateX(100%);
		transition: all 0.5s ease-in-out;
	}
	.carousel-left-enter {
		transform: translateX(-100%);
	}
	.carousel-left-leave {
		transform: translateX(0);
	}
	// 右侧进入
	.carousel-right-enter-active {
		transform: translateX(0);
		transition: all 0.5s ease-in-out;
	}
	.carousel-right-leave-active {
		transform: translateX(-100%);
		transition: all 0.5s ease-in-out;
	}
	.carousel-right-enter {
		transform: translateX(100%);
	}
	.carousel-right-leave {
		transform: translateX(0);
	}
}
ol,
ul {
	list-style: none;
}
</style>
