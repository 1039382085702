<template>
        <div class="small-title">
        <div class="line"></div>
        <div class="title-style">{{smallTitle}}</div>
        <div class="line"></div>
    </div>
</template>
<script>
export default {
     name: "SmallTitle",
     props: {
        smallTitle: {
            type: String,
        },
    }
}
</script>
<style lang="scss" scoped>
.small-title{
    display: flex;
    align-items: center;
    .line{
        width:550px;
        height:1px;
        border-bottom:1px solid #BFC7D1;
    }
    .title-style{
        font-size: 16px;
        color: #BFC7D1;
    }
}
</style>