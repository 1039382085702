<template>
 <div class="cooperative-partner-box">
        <div class="range_carousel" :class="{'no-display':carouselData.length==1 || carouselData.length<0}">
          <el-carousel
            height="5.1rem"
            class="carousel_box"
            :autoplay="false"
            arrow="never"
          >
            <el-carousel-item
              v-for="(item,index) in carouselData"
              :key="index"
            >
              <div class="carousel_content">
                <img
  
                  :src="item.url"
                  alt=""
                >
                <div class="text-wrap">
                    <div class="one-title">枫调理顺，基于区块链为核心的<br>社会治理与纠纷解决方案商</div>
                    <div class="oprate">
                    <el-button class="button1" @click="goRouter">查看解决方案</el-button>
                    <el-button class="button2" @click="openVideo" >观看介绍视频</el-button>
                </div>
                </div>
                
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
</template>
<script>
export default {

    name: "Carousel",
    props:{
            carouselData:{
        type:Array,
      default:()=>{
        return []
      }
      },
    },
      data() {
        return {
         }
    },
    methods:{
      openVideo(){
        let video = require("@/assets/media/预付消费介绍视频.mp4")
        window.open(video)
      },
      goRouter(){
         this.$router.push({
          path: "/coreBusiness/SocialGovernance"
        });
      }
    }
}
</script>
<style lang='scss' scoped>
.no-display{
  /deep/.el-carousel__indicator--horizontal{
    display: none;
  }
}
.range_carousel{
  /deep/.el-carousel__indicator--horizontal{
  padding: 0.26rem 0.10rem;
  .el-carousel__button{
    width:0.50rem;
    height:0.06rem;
    border-radius: 0.04rem;
  }
}
}
.oprate{
  .button1{
    font-size: 0.18rem;
    background: #0336BF;
    color:#fff;
}
.button2{
  color:#3E3E3E;
   font-size: 0.18rem;
}
}
.carousel_content{
    position: relative;
    font-family: PingFangSC-Semibold;
    img{
        width:100%;
        // height:6.83rem
        height:auto;
    }
    .one-title{
        width:7.6rem;
        font-size: 0.5rem;
        color: #1F2639;
        font-weight: 600;
        margin-bottom:0.28rem;
        margin-top:1.00rem;
    }
    .sub-title{
      font-size: 0.18rem;
      color: #6E7A96;
      padding-bottom:1rem;
    }
    .text-wrap{
        z-index:10;
        position: absolute;
        top: 0.8rem;
        left:50%;
        width:14.32rem;
        transform: translateX(-50%);
    }
}

</style>