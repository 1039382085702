<template>
  <div class="page-wrap" id="home">
    <app-header class="app-header"></app-header>
    <div class="part-one">
      <div class="part-one-content">
        <div class="part-one-img">
          <Title :content="content1"></Title>
          <!-- <img src="@/assets/home/bg2.png" alt=""> -->
          <p class="introduce">
            北京枫调理顺科技发展有限公司是一家以科技支撑，通过辅助党委和政府，整合调动社会各方力量，协同参与社会治理的企业。在这个过程中，我们不仅要把矛盾解决提前到风险预防，也在积极推动将稳定的治理机制沉淀为制度，协助政府将之上升至法律，有效且长效地化解纠纷，努力促进社会的安定、有序发展，促进社会文明。
          </p>
        </div>
      </div>
    </div>
    <div class="part-two" id="yw">
      <!-- 核心业务 -->
      <Title :content="content2"></Title>
      <div class="part-two-content">
        <div
          class="part-two-bg"
          v-for="(item, index) in partTwo"
          :key="index"
          :class="item.bg"
          @click="goRouter(item)"
        >
          <div class="part-two-title">{{ item.title }}</div>
          <div class="view">{{ item.tetail }}</div>
        </div>
      </div>
    </div>
    <!-- 核心优势 -->
    <div class="part-three" id="cooperation">
      <Title :content="content3"></Title>
      <div class="part-three-bg">
        <div class="part-three-content">
          <div class="part-three-box">
            <div
              class="part-three-item"
              :index="item.title"
              v-for="(item, index) in advantages"
              :key="index"
              :class="item.stroke"
            >
              <img :src="item.img" alt="" />
              <div class="title-style">{{ item.title }}</div>
              <div class="desc">
                <p class="desc1">{{ item.content }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 新闻动态 -->
    <div class="part-four" id="cooperation">
      <Title :content="content4"></Title>
      <div class="part-four-content">
        <!-- <div class="new-img"> -->
        <div class="part-four-bg">
          <div style="padding-left:0.38rem;">
            <div class="hot">最新热点</div>
            <div class="small">行业最新动态集锦</div>
            <div class="detail" @click="goComDynamics">查看详情</div>
          </div>
        </div>
        <!-- </div> -->
        <div class="new-info">
          <div
            class="info-item"
            @click="goDetail(item.id)"
            v-for="(item, index) in newList"
            :key="index"
          >
            <div class="info-content">
              {{ item.title }}
            </div>
            <p class="time1">{{ $moment(item.time).format("YYYY-MM-DD") }}</p>
          </div>
        </div>
        <div></div>
      </div>
    </div>

    <!-- 荣誉资质 -->
    <div class="carousel-box-wrap">
      <!-- <div class="zizhi-part"> -->
      <Title :content="content5"></Title>
      <!-- </div> -->
      <div class="carousel-box-bg">
        <div class="carousel-box">
          <swiper ref="mySwiper" :options="swiperOptions">
            <swiper-slide
              class="item-device"
              v-for="(item, index) in zizhi"
              :key="index"
            >
              <div class="device" @click="goScaleImg(item.img)">
                <div class="dev-img">
                  <img :src="item.img" width="90%" alt="" />
                  <!-- <el-image 
                            style="width:90%"
                            :src="item.img" 
                            :preview-src-list="[item.img]">
                           </el-image> -->
                </div>
              </div>
            </swiper-slide>
            <div class="swiper-button-prev" ref="prevBtn" slot="button-prev">
              <i class="el-icon-arrow-left"></i>
            </div>
            <div class="swiper-button-next" ref="nextBtn" slot="button-next">
              <i class="el-icon-arrow-right"></i>
            </div>
          </swiper>

          <!-- <imgPreDialog :config="imgPreData"></imgPreDialog> -->

          <div class="btnContainer">
            <span class="button button1" @click="leftClick">
              <i class="el-icon-arrow-left"></i
            ></span>
            <span class="button button2" @click="rightClick">
              <i class="el-icon-arrow-right"></i
            ></span>
          </div>

          <!-- <el-carousel :interval="4000" type="card" height="300rem" :autoplay="false" :arrow="always">
          <el-carousel-item v-for="(item,index) in zizhi" :key="index">
            <img :src="item.img" alt="">
          </el-carousel-item>
          </el-carousel> -->
        </div>
      </div>
    </div>
          <imgPreDialog :config="imgPreData"></imgPreDialog>

    <!-- 合作伙伴 -->
    <div class="part-six" id="cooperation">
      <Title :content="content6"></Title>
      <!-- <div class="small-wrap">
        <small-title :smallTitle="smallTitle"></small-title>
     </div> -->
      <div class="part-six-content">
        <div class="part-six-img">
          <img
            :src="item.img"
            alt
            v-for="(item, index) in cooPartners"
            :key="index"
          />
        </div>
        <BackTop>
          <img class="back" src="@/assets/home/back.png" />
        </BackTop>
      </div>
      <!-- <div>
          <div class="small-wrap">
        <small-title :smallTitle="smallTitle2"></small-title>
     </div>
      <div class="part-six-content">
        <div class="part-six-img">
           <img :src="item.img" alt v-for="(item,index) in cooPartners" :key="index" />
        </div>
      </div>
      </div> -->

      <el-dialog
        title="荣誉资质图片"
        :visible.sync="imgDialogVisible"
        :modal-append-to-body="false"
        width="40%"
        center
      >
        <div style="width:100%;text-align: center;">
          <!-- <img :src="tempImg" alt="" > -->

          <el-image style="width:90%" :src="tempImg"> </el-image>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="imgDialogVisible = false">关闭</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import imgPreDialog from "@/components/commom/imgPreDialog.vue";
import Title from "@/components/layer/OneTitle.vue";
import AppHeader from "@/components/layer/AppHeader";
import SmallTitle from "@/components/layer/SmallTitle";
import api from "@/api/index";
import { baseURL } from "@/config";
import BackTop from "@/components/commom/BackTop.vue";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
export default {
  components: {
    Title,
    AppHeader,
    SmallTitle,
    swiper,
    swiperSlide,
    BackTop,
    imgPreDialog,
  },
   metaInfo: {
      title: '枫调理顺-社会治理与纠纷解决方案商', //设置页面title
      meta: [{                 //设置页面meta
        name: 'keyWords',
        content: '商户跑路'
      },
      {
        name: "keyWords",
        content: "卷款跑路",
      },
       {
        name: "keyWords",
        content: "消费维权",
      },
        {
        name: "keyWords",
        content: "欠款追回",
      },
       {
        name: "keyWords",
        content: "预付消费",
      },
       {
        name: "keyWords",
        content: "预付卡",
      },
       {
        name: "keyWords",
        content: "充值卡",
      },
        {
        name: "keyWords",
        content: "充值",
      },
       {
        name: "keyWords",
        content: "预付充值",
      },
       {
        name: "keyWords",
        content: "付费",
      },
       {
        name: "keyWords",
        content: "跑路",
      },
       {
        name: "keyWords",
        content: "商家",
      },
       {
        name: "keyWords",
        content: "美容美发",
      },
       {
        name: "keyWords",
        content: "健身",
      },
       {
        name: "keyWords",
        content: "剪发卡",
      },
      {
        name: "keyWords",
        content: "理发卡",
      },
       {
        name: "keyWords",
        content: "洗浴卡",
      },
       {
        name: "keyWords",
        content: "预付费卡",
      },
       {
        name: "keyWords",
        content: "预付式消费",
      },
      ]
  },
  components: { Title, AppHeader,SmallTitle,swiper, swiperSlide,BackTop,imgPreDialog },
  data() {
    return {
       screenWidth: document.body.clientWidth,  //屏幕可视区宽度
      // 图片预览数据
      imgPreData: {
        show: false,
        img: "",
      },
      imgDialogVisible: false,
      tempImg: "",
      swiperOptions: {
        // speed:500,
        slidesPerView: 5, // 可见数量
        paginationClickable: true,
        freeMode: true,
        spaceBetween: 10,
        // history: 'love',
        // 设置点击箭头
        navigation: {
          nextEl: ".swiper-button-prev",
          prevEl: ".swiper-button-next",
        },
        // autoplay:{
        //   delay:3500,
        //   autoplayDisableOnInteraction:false
        // },
        centeredSlides: true,
        loop: true,
        preventClicks: false, //默认true
      },
      pageNum: 1, // 当前页码
      pageSize: 3, // 总页数 1 5
      categoryIndex: 1,
      smallTitle: "战略合作伙伴",
      smallTitle2: "优秀合作伙伴",
      content1: {
        name: "公司介绍",
        egName: "COMPANY PROFILE",
      },
      content2: {
        name: "核心业务",
        egName: "CORE BUSINESS",
      },
      content3: {
        name: "核心优势",
        egName: "CORE ADVANTAGES",
      },
      content4: {
        name: "新闻动态",
        egName: "COMPANY NEWS",
      },
      content5: {
        name: "荣誉资质",
        egName: "HONORORY QUALIFICATION",
      },
      content6: {
        name: "合作伙伴",
        egName: "COOPERATIVE PARTNERS",
      },
      zizhi: [
        {
          img: require("@/assets/home/zizhi5.jpeg"),
        },
        {
          img: require("@/assets/home/zizhi6.jpeg"),
        },
        {
          img: require("@/assets/home/zizhi7.jpeg"),
        },
        {
          img: require("@/assets/home/zizhi8.jpeg"),
        },
        {
          img: require("@/assets/home/zizhi9.jpeg"),
        },
        // {
        //   img: require("@/assets/home/zizhi10.jpeg"),
        // },

        // ,{
        //   img: require("@/assets/home/zizhi5.jpeg"),
        // },
        // {
        //   img: require("@/assets/home/zizhi6.jpeg"),
        // },
        // {
        //   img: require("@/assets/home/zizhi7.jpeg"),
        // },{
        //   img: require("@/assets/home/zizhi8.jpeg"),
        // },
        // {
        //   img: require("@/assets/home/zizhi9.jpeg"),
        // }
      ],
      srcList: [],
      partOne: [
        {
          img: require("@/assets/home/01.png"),
          title: "社会矛盾纠纷预警",
          content:
            "物业纠纷/医疗纠纷/邻里纠纷/行政纠纷/环境污染/商事纠纷/消费纠纷/道交纠纷/信用卡纠纷/……",
        },
        {
          img: require("@/assets/partone/02.png"),
          title: "发现社会矛盾",
          tip1: "单用途预付卡监管方案",
          content:
            " 环资一体化诉/访源治理方案行政争议诉/访源治理方案热力纠纷/访源治理方案",
        },
        {
          img: require("@/assets/partone/03.png"),
          title: "基层多元化解",
          tip1: "法院非诉(诉前）",
          content: "法官调解/律师调解/人民调解/行政调解/公证/仲裁/行政裁决…… ",
          tip2: "司法非诉",
        },
        {
          img: require("@/assets/partone/04.png"),
          title: "最终解决途径",
          tip1: "诉讼解决",
          content: "速审/速裁/速执行…… ",
          tip2: "信访解决",
        },
      ],
      partTwo: [
        {
          img: require("@/assets/home/yw1.png"),
          bg: "bg1",
          title: "社会治理",
          tetail: "查看详情",
          url: "/coreBusiness/SocialGovernance",
        },
        {
          img: require("@/assets/home/yw2.png"),
          title: "至信存证",
          tetail: "查看详情",
          link: "https://www.zxinchain.com/",
          bg: "bg2",
        },
        {
          img: require("@/assets/home/yw3.png"),
          title: "人才培养",
          tetail: "查看详情",
          url: "/PersonTrain",
          bg: "bg3",
        },
        {
          img: require("@/assets/home/yw4.png"),
          title: "多元解纷",
          tetail: "查看详情",
          url: "/Solution",
          bg: "bg4",
        },
      ],
      advantages: [
        {
          img: require("@/assets/home/hx1.png"),
          title: "深耕政法领域",
          content: "团队核心成员服务政法、政务领域20余年",
          stroke: "stroke1",
        },
        {
          img: require("@/assets/home/hx2.png"),
          title: "引领行业实践",
          content:
            "创新社会治理服务方案和可持续的商业模式，目前已落地多个应用场景",
          stroke: "stroke2",
        },
        {
          img: require("@/assets/home/hx3.png"),
          title: "技术领先",
          content: "提供一站式社会治理区块链解决方案",
          stroke: "stroke3",
        },
        {
          img: require("@/assets/home/hx4.png"),
          title: "司法认可",
          content: "至信链司法存证，证据直通法院",
          stroke: "stroke4",
        },
        {
          img: require("@/assets/home/hx5.png"),
          title: "服务安全稳定",
          content: "专业运营团队，保证项目稳定运行",
          stroke: "stroke5",
        },
      ],
      cooPartners: [
        {
          img: require("@/assets/home/logo9.png"),
        },
        {
          img: require("@/assets/home/logo16.png"),
        },
        {
          img: require("@/assets/home/logo13.png"),
        },
        {
          img: require("@/assets/home/logo19.png"),
        },
        {
          img: require("@/assets/home/logo20.png"),
        },
        {
          img: require("@/assets/home/logo21.png"),
        },

        {
          img: require("@/assets/home/logo3.png"),
        },
        {
          img: require("@/assets/home/logo1.png"),
        },
        {
          img: require("@/assets/home/logo6.png"),
        },
        {
          img: require("@/assets/home/logo7.png"),
        },
        {
          img: require("@/assets/home/logo8.png"),
        },

        {
          img: require("@/assets/home/logo5.png"),
        },
        {
          img: require("@/assets/home/logo10.png"),
        },
        {
          img: require("@/assets/home/logo11.png"),
        },
        {
          img: require("@/assets/home/logo12.png"),
        },
        {
          img: require("@/assets/home/logo4.png"),
        },
        {
          img: require("@/assets/home/logo14.png"),
        },
        {
          img: require("@/assets/home/logo15.png"),
        },
        {
          img: require("@/assets/home/logo2.png"),
        },
        {
          img: require("@/assets/home/logo17.png"),
        },
        {
          img: require("@/assets/home/logo18.png"),
        },
      ],
      excellentPartners: [
        {
          img: require("@/assets/partthree/01.png"),
        },
        {
          img: require("@/assets/partthree/02.png"),
        },
        {
          img: require("@/assets/partthree/03.png"),
        },
        {
          img: require("@/assets/partthree/04.png"),
        },
        {
          img: require("@/assets/partthree/05.png"),
        },
        {
          img: require("@/assets/partthree/01.png"),
        },
        {
          img: require("@/assets/partthree/02.png"),
        },
        {
          img: require("@/assets/partthree/03.png"),
        },
        {
          img: require("@/assets/partthree/04.png"),
        },
        {
          img: require("@/assets/partthree/05.png"),
        },
      ],
      newList: [
        {
          title: "金融审判信息化研讨会在深举办，“至信链”助力金融",
          time: "2022-4-1",
        },
        {
          title: "金融审判信息化研讨会在深举办，“至信链”助力金融",
          time: "2022-4-1",
        },
        {
          title: "金融审判信息化研讨会在深举办，“至信链”助力金融",
          time: "2022-4-1",
        },
      ],
    };
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper;
    },
  },
  mounted() {
    this.getList();

     // 监听窗口变化
      let _this = this
      window.addEventListener('resize',function () {
         _this.screenWidth = document.body.clientWidth;
      }) 
      
      setTimeout(()=>{
        this.rightClick()
      },2000)

  },


  watch: {
      // 窗口宽度改变
      screenWidth(){
        this.$refs.mySwiper.initSwiper()
      }
   },
  methods: {
    goScaleImg(img) {
      //  this.imgDialogVisible =true
      //  this.tempImg = img

      this.imgPreData.show = true;
      this.imgPreData.img = img;
    },
    //  prev() {
    //   this.swiper.slidePrev();
    // },
    // next() {
    //   this.swiper.slideNext();
    // },
    leftClick() {
      this.$refs.nextBtn.click();
    },

    rightClick() {
      this.$refs.prevBtn.click();
    },
    goRouter(obj) {
      if (obj.link) {
        window.open(obj.link, "_blank");
      } else {
        this.$router.push(obj.url);
      }
    },
    // 路由跳转
    goDetail(id) {
      this.$router.push({
        path: "/DynamicDetail",
        query: { id: id },
      });
    },
    goComDynamics() {
      this.$router.push({
        path: "/ComDynamics",
      });
    },
    // 动态新闻
    getList() {
      let categoryId = 1; //  首页 1
      api.news
        .getList({
          articleId: null,
          categoryId: categoryId,
          pageNum: this.pageNum,
          pageSize: this.pageSize, // 1 5
        })
        .then((res) => {
          if (res.code == 0) {
            let pageInfo = res.data.pageInfo;
            this.total = pageInfo.total;
            if (pageInfo && pageInfo.list && pageInfo.list.length) {
              pageInfo.list.forEach((item) => {
                item.url = baseURL + item.coverImage;
                item.title = item.title;
                item.time = item.publishTime;
                item.content = item.description;
              });
              this.newList = pageInfo.list;
            }
          }
        });
    },
    clickCategory(index) {
      // 这里我们传入一个当前值
      this.categoryIndex = index;
      console.log(index, "index");
    },
  },
};
</script>

<style lang="scss" scoped>
.page-wrap {
  width: 100%;
  font-family: PingFangSC-Regular;
  // overflow: scroll;
  overflow-x: hidden;
  background: #f8fbff;
}
.part-one {
  background-color: #fff;
  width: 100%;
  position: relative;
  .title-c {
    position: absolute;
    top: 30px;
  }
  .title-c .content .eg-name {
    padding: 0;
  }
  .title-c {
    position: relative;
    top: 0.6rem;
    padding-top: 0rem;
    z-index: 3;
  }
  .part-one-content {
    display: flex;
    align-items: center;
    margin: 0 auto;
    .part-one-img {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 6rem;
      background: url("../../assets/home/bg2.png") no-repeat;
      background-size: 100% 100%;
      position: relative;
      .title-c {
        position: absolute;
        width: 100%;
      }
    }
    .introduce {
      width: 6.36rem;
      height: 2.13rem;
      line-height: 0.4rem;
      font-size: 0.18rem;
      color: #1c1c1c;
      text-indent: 0.4rem;
      position: absolute;
      right: 2.16rem;
    }
  }
}
.part-two {
  .solution-bg {
    width: 100%;
    height: 4.5rem;
    background: url(../../assets/partone/05.png) no-repeat center;
    .solution-content {
      display: flex;
      align-items: center;
      // justify-content: space-around;
      .solution-list {
        display: flex;
        height: 3rem;
        flex-direction: column;
        border-right: 0.01rem solid #dde1e6;
        margin-left: 2.07rem;
        padding-right: 0.5rem;
        padding-top: 0.5rem;
        .title {
          display: flex;
          align-items: center;
          padding-bottom: o.60rem;
        }
        .solution-title {
          color: #354657;
          font-weight: bold;
          font-size: 0.16rem;
          cursor: pointer;
        }
        .on {
          color: #3578e3;
          font-size: 0.2rem;
          font-weight: bold;
          transition: 0.5s;
        }
        .line {
          margin: 0.1rem;
          width: 0.2rem;
          height: 0.015rem;
          background: #3f68d0;
          transition: 0.5s;
        }
        .off {
          color: #3f68d0;
          font-size: 0.2rem;
          font-weight: bold;
          transition: 0.5s;
        }
      }
      .solution-detail {
        display: flex;
        flex-direction: column;
        margin-left: 0.38rem;
        width: 7.34rem;
        height: 3.65rem;
        background: rgba(255, 255, 255, 1);
        border: 1rem solid rgba(221, 225, 230, 1);
        box-shadow: 0rem 0.02rem 0.3rem 0rem rgba(181, 183, 194, 0.16);
        .part-two-img {
          width: 7.34rem;
          height: 2.46rem;
        }
        .solution-detail-title {
          padding: 0.19rem 0 0 0.32rem;
          font-size: 0.18rem;
          color: #3578e3;
          font-weight: bold;
        }
        .solution-detail-info {
          font-size: 0.14rem;
          font-weight: bolder;
          color: #313131;
          padding: 0.16rem 0 0 0.32rem;
          width: 6.6rem;
          p {
            letter-spacing: 0.02rem;
          }
        }
      }
    }
  }
}
.part-two {
  background-color: #f8fbff;
  width: 100%;
  .part-two-content {
    // width: 12rem;
    width: 14.32rem;
    display: flex;
    justify-content: space-around;
    margin: 0 auto;
    .part-two-bg:hover {
      cursor: pointer;
    }
    .part-two-bg {
      width: 3.26rem !important;
      height: 4.36rem;
      //  width:2.72rem;
      // height: 3.64rem;
      box-sizing: border-box;
      border: 0.01rem solid #f8f8f8;
      box-shadow: 0 0.1rem 0.4rem 0 rgba(12, 12, 12, 0.03);
      border-radius: 0.08rem;
      padding: 0.38rem 0 0 0.37rem;
      padding-right: 0.43rem;
      background-size: 100% 100%;
      .view {
        cursor: pointer;
        font-size: 0.18rem;
      }
      &:hover {
        color: #fff;
      }
      &:hover {
        .part-two-title {
          color: #fff;
        }
      }
      span {
        font-size: 0.14rem;
        color: #9da3ad;
      }
      .part-two-img {
        width: 1.13rem;
        height: 0.86rem;
        margin-top: 0.58rem;
      }
      .part-two-title {
        font-family: PingFangSC-Semibold;
        font-weight: 600;
        font-size: 0.28rem;
        color: #1c1c1c;
        padding-bottom: 0.16rem;
      }
      .line {
        margin-top: 0.08rem;
        width: 0.53rem;
        height: 0.04rem;
        background: #3478e3;
      }
      .part-two-detail {
        margin-top: 0.22rem;
        font-size: 0.14rem;
        color: #354657;
        width: 2.06rem;
        height: 0.54rem;
        font-weight: 400;
        .t1 {
          color: #354657;
          font-weight: bold;
        }
      }
    }
  }
}
.part-three {
  background: white;
  .title-c {
    background: #f8fbff;
  }
  .part-three-bg {
    background: #f8fbff;
  }
  .part-three-content {
    // width: 12rem;
    width: 14.32rem;
    margin: 0 auto;
    .part-three-box {
      display: flex;
      flex-wrap: wrap;
      text-align: center;
      justify-content: center;
      background-size: 100% 100%;
      box-sizing: border-box;
      .part-three-item {
        box-sizing: border-box;
        // padding-top:0.40rem;
        width: 3.9rem;
        padding-bottom: 1.16rem;
        .title-style {
          font-weight: 600;
          font-size: 0.22rem;
          color: #1c1c1c;
          margin: 0.27rem 0 0.15rem 0;
          .desc {
            font-size: 0.18rem;
            color: #8c99aa;
          }
        }
      }
    }
    img {
      width: 0.65rem;
      height: 0.65rem;
    }
  }
}
.part-four {
  background: #f8fbff;
  .part-four-content {
    display: flex;
    // width: 12rem;
    width: 14.32rem;
    margin: 0 auto;
  }
  .part-four-bg {
    width: 8.63rem;
    height: 3.08rem;
    background-image: url("../../assets/home/nwebg.png");
    .hot {
      font-family: PingFangSC-Semibold;
      font-size: 0.28rem;
      color: #ffffff;
      padding-top: 0.24rem;
    }
    .small {
      font-size: 0.18rem;
      color: #ffffff;
      margin-top: 0.24rem;
      margin-bottom: 0.7rem;
    }
    .detail {
      width: 1.2rem;
      height: 0.36rem;
      border: 0.01rem solid #ffffff;
      text-align: center;
      color: #fff;
      line-height: 0.36rem;
      font-size: 0.16rem;
      cursor: pointer;
    }
  }
  .new-info {
    height:3.08rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: #1c1c1c;
    margin-left: 0.12rem;
    padding: 0.44rem 0.39rem 0.41rem 0.34rem;
    width: 4.92rem;
    background: #ffffff;
    border: 0.01rem solid #e2e9f1;
    box-shadow: 0 0.1rem 0.4rem 0 rgba(12, 12, 12, 0.03);
    box-sizing: border-box;
    .info-item {
      box-sizing: border-box;
      margin-bottom:0.3rem;
      .info-content {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        -o-text-overflow: ellipsis;
        font-weight: 600;
        font-size: 0.18rem;
        cursor: pointer;
        // margin-bottom: 0.2rem;
      }
      .time1 {
        font-size: 0.16rem;
        margin-top: 0.1rem;
        color:#666;
      }
    }
    .info-item:last-child {
      margin-bottom: 0;
    }
  }
}
.part-five {
  background: rgba(247, 249, 250, 1);
  .part-five-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .img-wrap {
      width: 1.85rem;
      height: 1.85rem;
      background: rgba(255, 255, 255, 1);
      border: 0.01rem solid rgba(51, 51, 51, 0.2);
      border-radius: 0.26rem;
      img {
        width: 100%;
        height: 100%;
        border-radius: 0.26rem;
      }
    }
    .txts1 {
      margin-top: 0.17rem;
      font-size: 20px;
      font-family: PingFang SC;
      font-weight: 400;
      color: rgba(51, 51, 51, 1);
    }
    .txts2 {
      width: 5.58rem;
      text-align: center;
      margin-top: 0.32rem;
      font-size: 018rem;
      font-family: PingFang SC;
      font-weight: 400;
      color: rgba(102, 102, 102, 1);
    }
    .down-btn {
      text-align: center;
      margin-top: 0.5rem;
      margin-bottom: 0.66rem;
      font-size: 0.2rem;
      width: 2.5rem;
      height: 0.62rem;
      line-height: 0.62rem;
      background: rgba(52, 120, 227, 1);
      border-radius: 0.04rem;
      color: #fff;
      cursor: pointer;
      .img {
        margin-right: 0.05rem;
        vertical-align: -0.02rem;
      }
    }
  }
}
.part-six {
  .small-wrap {
    width: 14.32rem;
    margin: 0 auto;
    margin-bottom: 0.4rem;
  }
  .part-six-content {
    width: 14.32rem;
    margin: 0 auto;
    .back {
      width: 0.42rem;
      height: 0.42rem;
      position: fixed;
      bottom: 2.4rem;
      right: 0.49rem;
      cursor: pointer;
    }
    .part-six-img {
      display: flex;
      flex-wrap: wrap;
      padding-bottom: 0.4rem;
    }
    img {
      // width: 2.23rem;
      width: 2.68rem;
      height: 1.13rem;
      margin-right: 0.22rem;
      margin-bottom: 0.25rem;
      &:nth-of-type(5n){
      margin-right: 0rem;

      }
    }
  }
}
.bg1 {
  background-image: url("../../assets/home/yw1.png");
  &:hover {
    background-image: url("../../assets/home/h-yw1.png");
  }
}
.bg2 {
  background-image: url("../../assets/home/yw2.png");
  &:hover {
    background-image: url("../../assets/home/h-yw2.png");
  }
}
.bg3 {
  background-image: url("../../assets/home/yw3.png");
  &:hover {
    background-image: url("../../assets/home/h-yw3.png");
  }
}
.bg4 {
  background-image: url("../../assets/home/yw4.png");
  &:hover {
    background-image: url("../../assets/home/h-yw4.png");
  }
}
.stroke2 {
  border-right: 1px solid #e9ebf1;
  border-left: 1px solid #e9ebf1;
  border-image: linear-gradient(#f6f8fa, #eaeef3) 0 100;
}
.stroke4 {
  border-top: 1px solid #e9ebf1;
  border-right: 1px solid #e9ebf1;
  border-image: linear-gradient(#f6f8fa, #eaeef3) 0 -1;
}
.stroke5 {
  border-top: 1px solid #e9ebf1;
  border-image: linear-gradient(#f6f8fa, #eaeef3) 0 -1;
}
.carousel-box-wrap {
  position: relative;
  // .zizhi-part{
  // border:1px solid red;
  // position: absolute;
  // }
  .swiper-button-prev {
  }
  .swiper-button-next {
  }

  /deep/.title-c {
    position: absolute;
    width: 100%;
    .content {
      .name {
        color: #fff;
      }
      .eg-name {
        color: #fff;
      }
    }
  }
}
.carousel-box-bg {
  // width:14.40rem;
  padding-left: 1rem;
  padding-right: 1rem;
  height: 6.5rem;
  margin: 0.6rem auto 0 auto;
  background-image: url("../../assets/home/zizhibg.png");
  background-size: 100% 100%;
  // display: flex;
  // justify-content: center;
  // align-items: center;
}
.carousel-box {
  width:15rem;
  margin-top: 0.4rem;
  // border:1rem solid red;
  position: relative;
  height: 6.5rem;
  margin: 0rem auto;
   transform: translateX(1.08rem) !important;


  /deep/ .swiper-container {
    height: 6.5rem;
    display: flex;
    align-content: center;
    transform: translateX(-1.2rem) !important;
    //  overflow-x: hidden !important;
    //  overflow-y: visible !important;
  }
  /deep/ .swiper-slide {
    opacity: 0.7;
    display: flex;
    justify-content: center;
    align-items: center;
    // margin-right:0.52rem !important;
    // margin-left: ;
    // margin:0rem 0.125rem !important;
    // transform: translateX(-80px);
  }
  /deep/.swiper-wrapper {
    display: flex;
    align-items: center;
    margin-top: 0.5rem;
  }
  /deep/ .swiper-slide-active {
    opacity: 1;
    transform: scale(1.2, 1.2);
    z-index: 30;
    // width:22% !important;
    transform-origin: center center;
    // transform: translateX(-82px);

  }
  /deep/ .swiper-button-prev,
  /deep/.swiper-button-next {
    visibility: hidden;
    width: 0.51rem;
    height: 0.51rem;
    text-align: center;
    line-height: 0.51rem;
    background: #ffffff;
    border-radius: 50%;
    top: 5.2rem;
    position: absolute;
    img {
      width: 100%;
      height: 100%;
    }
  }
  /deep/ .swiper-button-prev {
    left: 5.3rem;
  }
  /deep/ .swiper-button-next {
    right: 5.3rem;
  }
  .el-carousel__item--card {
    width: 20%;
  }
  /deep/ .el-carousel__mask {
    background: rgba(10, 12, 43, 0.7);
  }

  .btnContainer {
    position: absolute;
    left: 50%;
    margin-left: -7.16rem;
    // width: 12rem;
    width: 14.32rem;
    transform: translateX(-1.15rem) !important;

    color: #000;
    position: absolute;
    z-index: 1;
    height: 20px;
    // border: 1px solid red;
    .button {
      position: absolute;
      background: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      width: 0.51rem;
      height: 0.51rem;
      color: #0336bf;
      cursor: pointer;
      i {
        font-size: 0.3rem;
      }
    }
    .button1 {
      z-index: 10;
      left: 5.22rem;
      top: -0.76rem;
    }
    .button2 {
      top: -0.76rem;
      right: 5.22rem;
      border-radius: 50%;
      z-index: 20;
    }
  }
}
.desc1 {
  width: 3.12rem;
  margin: 0 auto;
  color: #8c99aa;
  font-size: 0.16rem;
  line-height: 0.22rem;
}
.dev-img {
  width: 2.2rem;

  img {
    // border: 14px solid #8c99aa;
    border: 14px solid #fff;
  border-radius: 6px;

  }
}
</style>
